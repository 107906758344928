import React, { useState } from 'react';
import './App.css';
import Login from './components/Login';
import Register from './components/Register';
import Logo from './assets/synergylogo.png';
import { User } from './Types';
import Verify from "./components/Verify";

function App() {

  const [isRegistering, setIsRegistering] = useState<boolean | null>(false);
  const [isVerifying, setIsVerifying] = useState<boolean | null>(null);
  const [registeredUser, setRegisteredUser] = useState<User | null>(null);

  const handleRegistrationResponse = (user: User | false) => {
      if (!user) {
          setIsRegistering(true)
      } else {
          setRegisteredUser(user);
          setIsRegistering(null)
          setIsVerifying(false)
      }
  };

  return (
      <div className="app">
        <header className="app-header">
          <img src={Logo} alt={"Synergy"} className="logo" />
            {isVerifying && registeredUser ? (
                <div className="container2">
                    <Verify user={registeredUser}/>
                </div>
            ) : (
                <div>
                    {isRegistering ? (
                        <div className="container2">
                            <Register onRegistrationResponse={handleRegistrationResponse}/>
                            <button onClick={() => setIsRegistering(false)}
                                    className="custom-button">Already have an account?
                            </button>
                             </div>
                        ) : (
                             <div className="container">
                                 <Login/>
                                    <button onClick={() => setIsRegistering(true)}
                                         className="custom-button">Don't have an account?
                                    </button>
                             </div>
                    )}</div>
                )}
            </header>
      </div>
  );
}

export default App;