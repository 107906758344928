// src/components/Verify.tsx
import React, { useState } from 'react';
import { User } from '../Types';

interface VerifyProps {
    user: User;
}

const Verify: React.FC<VerifyProps> = ({ user }) => {
    const [verification, setVerification] = useState<string>();

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        try {
            const response = await fetch('/api/users/verify', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ user, verification }),
            });

            if (response.ok) {
                alert('Verification Successful!');
            } else {
                alert('Verification Failed!');
            }
        } catch (error) {
            console.error('Error:', error);
            alert('An error occurred. Please try again.');
        }
    };

    return (
        <div className="content">
            <label className="center-text">Please Login to Continue</label>
            <form onSubmit={handleSubmit}>
                <div className="input-group">
                    <label className="label">Username </label>
                    <input type="text" value={verification} onChange={(e) => setVerification(e.target.value)}/>
                </div>
                <button type="submit" className="custom-button">Submit Code</button>
            </form>
        </div>
    );
};

export default Verify;
